/*
* This method will call backend API to get google auth url if user is new else
* it will redirect to home page if user session is created.
* Steps:
*   1. Call API
*   2. Redirect user to url provided by backend
* Author: Daksh
* */
window.login_action=function () {

    let url = "/api/v1/auth";

    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((response) => {

        let json = response.json();

        if (response.status === 200 ) {
            return json;
        } else {
            return json.then(err => {
                throw err;
            });
        }
    })
        .then((jsonResponse) => {
            window.location.href = jsonResponse["redirect_url"];
        }).catch(() => {
    });
};
